<template>

  <div id="page" class="container-fluid">
    <div class="row">
      <div class="col">
        <RetroImage src="/img/palm-trees.jpg"/>
        <p>&nbsp;</p>
        <div class="container">
          <h1 class="text-center">Welcome to Sunnyvale</h1>
          <h3 class="text-center">The safest city in California</h3>
          <p>Welcome to Sunnyvale, California, a city that seems like any other at first glance. From the manicured lawns and tidy homes of the suburbs to the bustling downtown district, everything appears normal. But scratch the surface, and you'll find a dark history and hidden secrets that lurk just beneath the surface.</p>
          <p>This setting book is your guide to exploring the occult and supernatural elements of Sunnyvale. Whether you're running a horror-themed campaign, investigating a mysterious disappearance, or simply looking for a new setting to explore, Sunnyvale has something to offer. From Lovecraftian horrors to urban legends come to life, this city is home to all manner of dark entities and eldritch powers.</p>
          <p>In the following pages, you'll find detailed information on the history, geography, culture, and society of Sunnyvale, as well as the supernatural and occult elements that make it unique. Explore key locations such as the abandoned asylum, the ancient redwoods, and the mysterious mansion on the hill. Meet notable NPCs like the eccentric millionaire, the mysterious cults, and the corrupt politicians. And run pre-made scenarios that will test your players' mettle and push them to the brink of madness.</p>
          <p>Whether you're a veteran tabletop gamer or a newcomer to the hobby, we hope that this setting book will provide you with hours of entertainment and endless possibilities for adventure. So pack your bags, grab your dice, and get ready to face the horrors that lie within Sunnyvale, California.</p>
        </div>

      </div>
    </div>
  </div>
</template>

<script>
import RetroImage from '@/components/RetroImage.vue';

export default {
    name: "Welcome",
    components: { RetroImage }
}
</script>

<style lang="scss" scoped>

</style>
