<template>
  <div class="location">
    <h1>Sunnyvale PD</h1>
    <h3>700 All America Way</h3>
    <p>On the surface, the Sunnyvale Police Department appeared to be run competently and effectively, keeping the city relatively safe. But behind the scenes, corruption was festering, all tracing back to City Hall.</p>
    <p>The Mayor at the time had his hand in many questionable dealings. He laundered money, took bribes from criminal organizations, and skimmed funds from various city projects. However, he needed a way to hide and justify the unaccounted money.</p>
    <p>That's where the Sunnyvale PD came in. The Mayor used his influence to allocate much larger budgets to the police department than needed. On paper, the additional funds went to salaries, equipment and training. In reality, a sizable chunk ended up in the Mayor's pockets.</p>
    <p>To hide the embezzlement and keep the scheme going, the Mayor relied on the protection and complicity of high-ranking officers within the police department. Cases that could expose the corruption were buried, witnesses threatened, and investigations steered away from City Hall.</p>
    <p>For the rank and file officers and citizens, life went on largely the same. But the rot at the top was poisoning the entire system. Millions of dollars meant for public services and programs were being syphoned away for the Mayor's personal gain, with the police department aiding and abetting the crime.</p>
    <p>The deeper truth lies in uncovering how far the corruption reaches within the Sunnyvale PD and City Hall, and bringing the culprits to justice. Exposing the Mayor's self-serving scheme and the complicit officers who enabled it may finally give the police department a chance to reform and properly serve the community again.</p>
    <p>&nbsp;</p>
  </div>
</template>

<script>
export default {
    name: "SunnyvalePD",
    components: { },
    mounted() { }
}
</script>

<style lang="scss" scoped>

</style>
