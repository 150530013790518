<template>
  <div id="body">
    <div class="screen-container">
      <div class="screen-wrapper">
        <div class="screen-wrapper wobbley glitch wobblex">
          <div class="screen-wrapper">
            <div id="screen" :style="{ backgroundImage: 'url(' + imgUrl + ')' }"></div>
          </div>
          <div class="scanlines"></div>
          <canvas class="vcr" width="640" height="360" style="filter: blur(1px);"></canvas>
          <canvas class="snow" width="320" height="180"></canvas>
        </div>
      </div>
      <div class="vignette"></div>
    </div>
  </div>
</template>

<script>
// import ScreenEffect from '@/libraries/ScreenEffect.js';
export default {
  name: 'RetroImage',
  props: {
    imgUrl: { type:String, default: '/img/palm-trees.jpg' }
    },
  data: function() {
      return {
      }
  },
  mounted() {

  },
  methods: {

  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss">
@import "@/assets/scss/RetroImage";
</style>
