<template>
  <div id="page-full">

    <div class="panel padding locations">

      <LocationSunnyvalePD id="sunnyvale-pd" />
      <LocationLasPalmasPark id="las-palmas-park" />

    </div>

    <div class="panel map-wrapper">
      <div id="map"></div>
    </div>

  </div>
</template>

<script>
import LocationSunnyvalePD from '@/locations/SunnyvalePD.vue';
import LocationLasPalmasPark from '@/locations/LasPalmasPark.vue';

export default {
    name: "Welcome",
    components: { LocationSunnyvalePD, LocationLasPalmasPark },
    mounted() {
      const L = window.L;
      var layer = new L.StamenTileLayer("toner");
      var map = new L.Map("map", {
          center: new L.LatLng(37.3704336,-122.0426944),
          zoom: 13
      });

      map.addLayer(layer);

      map.on('click', function(e) {
        console.log(e.latlng.lat + ',' + e.latlng.lng);
      });

      const markers = L.markerClusterGroup();

      let institutions = { icon: 'institution', iconShape: 'marker', backgroundColor: '#061f5c', textColor: '#fff', borderWidth: 0 };
      let parks = { icon: 'tree', iconShape: 'marker', backgroundColor: '#3a5c06', textColor: '#fff', borderWidth: 0 };

      markers.addLayer(L.marker([37.370406,-122.0401385], { icon: L.BeautifyIcon.icon(institutions) }).bindTooltip('<b>Sunnyvale PD</b>').on('click', () => this.scrollToElement('sunnyvale-pd')));
      markers.addLayer(L.marker([37.364746901139746,-122.0381040270451], { icon: L.BeautifyIcon.icon(parks) }).bindTooltip('<b>Las Palmas Park</b>').on('click', () => this.scrollToElement('las-palmas-park')));

      map.addLayer(markers);




      // L.marker([37.370406,-122.0401385], {
      //     icon: L.BeautifyIcon.icon(institutions)
      // }).addTo(map).on('click', () => this.scrollToElement('sunnyvale-pd'));

      // L.marker([37.364746901139746,-122.0381040270451], {
      //     icon: L.BeautifyIcon.icon(parks)
      // }).addTo(map).on('click', () => this.scrollToElement('las-palmas-park'));

    },
    methods: {
      scrollToElement(element) {
        console.log(document.querySelector('.' + element))
        window.scroll({
          top: document.getElementById(element).getBoundingClientRect().top + window.scrollY - 60,
          left: 0,
          behavior: 'smooth'
        });
      }
    }
}
</script>

<style lang="scss" scoped>
#map {
  height: calc(100vh - 49px);
}

.map-wrapper {
  position: -webkit-sticky; /* Safari */
  position: sticky;
  top: 49px;
}

.locations {
  padding-bottom: 50vh!important;
}
</style>
