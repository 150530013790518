<template>
  <div id="page">

    <h1>History</h1>

    <p>Under construction</p>
  </div>
</template>

<script>
export default {
    name: "History",
    components: { },
    mounted() {

    },
    methods: {
      
    }
}
</script>

<style lang="scss" scoped>

</style>
