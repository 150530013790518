<template>
  <div class="location">
    <h1>Las Palmas Park</h1>
    <h3>850 Russet Dr</h3>
    <p>Las Palmas Park in the 1980s seemed idyllic at first glance, but there were signs that not all was as it seemed.</p>
    <p>The tennis courts and baseball diamond saw frequent use, but some claimed to see shadowy figures lurking around the edges of the park after dark. A lone jogger disappeared one night, never to be seen again. The authorities wrote it off as a tragic accident.</p>
    <p>The playground appeared lively and bustling on the weekends, filled with children's joyful laughter. However, some mothers gossiped about noticing marks and scratches on their kids that they couldn't explain. Strange feral growls could sometimes be heard coming from the bushes after sunset.</p>
    <p>The picnic area was popular for celebrations during the day, but at night the empty tables took on an eerie stillness. Neighbors claimed to see lights flitting around and shadows moving among the large eucalyptus trees. But whenever someone investigated, they found nothing.</p>
    <p>The paved paths wound harmlessly through the park, used by locals exercising or spending time outdoors. But there were reports of people going missing over the years, vanishing without a trace as if they had stepped off the path and into thin air. The authorities were never able to find any clues.</p>
    <p>While Las Palmas Park served as a community hub during the sunny hours, as twilight fell an air of mystery and unease settled over the park. There were rumors of a sinister presence lurking just out of sight, preying on those who lingered after dark. The truth of what really happened to the park's missing visitors remains a mystery, waiting to be uncovered...</p>
    <p>&nbsp;</p>
  </div>
</template>

<script>
export default {
    name: "SunnyvalePD",
    components: { },
    mounted() { }
}
</script>

<style lang="scss" scoped>

</style>
